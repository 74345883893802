<template>
  <div id="reservationsCalendar" class="relative">

    <vs-row v-if="currentView === 'dayGridMonth'">
      <vs-col vs-type="flex" vs-justify="start" vs-align="center" vs-w="6">
        <vs-button color="primary" size="small" class="mr-2" icon="keyboard_arrow_left"
                   @click="() => {prevMonth()}"></vs-button>
        <datepicker :minimumView="'month'" :maximumView="'year'" :language="slSI" :format="customDateFormatter"
                    :monday-first="true" format="MMMM, yyyy"
                    @input="onDateChange" v-model="defaultDate"></datepicker>
        <vs-button color="primary" size="small " class="ml-2" icon="keyboard_arrow_right"
                   @click="() => {nextMonth()}"></vs-button>
      </vs-col>
    </vs-row>

    <FullCalendar
      v-if="defaultDate"
      class="fullCalendar"
      ref="fullCalendar"
      defaultView="dayGridMonth"
      :views="['dayGridMonth', 'dayGridWeek']"
      :header="calendarHeader"
      :plugins="calendarPlugins"
      :weekends="true"
      :first-day="1"
      :events="events"
      :default-date="defaultDate"
      :height="'auto'"
      :locale="siLocale"
      @dateClick="handleCalendarDateClick"
      @eventClick="handleEventClick"
      @datesRender="handleCalendarDatesRender"
      @dayRender="handleCalendarDayRender"
      @eventRender="handleEventRender"
      @viewSkeletonRender="handleViewChange"
    />

    <div id="parentx">
      <vs-sidebar position-right parent="body" default-index="1" color="primary" class="reservationSidebar"
                  :click-not-close="true" spacer
                  v-model="sidebarActive">

        <vs-button radius color="danger" type="border" icon="close" class="closeSidebarButton"
                   @click.stop="() => { sidebarActive = false; }"></vs-button>

        <Reservation v-if="reservationId" :reservationId="reservationId" @closeSideBar="closeSideBar"/>
      </vs-sidebar>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import Reservation from "./Reservation";
import siLocale from '@fullcalendar/core/locales/sl';
import {slovenianHolidays} from '../../../assets/utils/slovenianHolidays'
import Datepicker from 'vuejs-datepicker';
import {slSI} from 'vuejs-datepicker/dist/locale';
import companion from "@/assets/images/portrait/companion.svg";

export default {
  name: 'reservations-calendar',

  components: {
    Datepicker,
    FullCalendar,
    Reservation
  },
  data() {
    return {
      slSI,
      siLocale,
      companion: companion,
      calendarPlugins: [dayGridPlugin, interactionPlugin],
      defaultDate: null,
      events: [],
      reservationId: null,
      sidebarActive: false,
      couponsData: null,
      mode: null,
      currentView: 'dayGridMonth'
    }
  },

  computed: {
    calendarHeader() {
      return {
        left: this.currentView === 'dayGridWeek' ? 'prev,next today' : '',
        center: 'title',
        right: 'dayGridMonth,dayGridWeek'
      }
    }
  },

  async mounted() {
    const _this = this;

    // console.log("slovenianHolidays", slovenianHolidays);

    // _this.couponsData = _this.$store.getters.getCoupons;
    this.defaultDate = this.moment(this.$store.getters.getSelectedDateForReservations).format('YYYY-MM-DD');

    // _this.getCoupons().then(()=> {
    _this.getReservations();
    // });
  },

  methods: {
    handleViewChange(info) {
      console.log("handleViewChange", info);
      this.currentView = info.view.type;
    },
    renderHolidays() {
      const _this = this;

      _.each(slovenianHolidays, function (val, idx){
        if(val.DELA_PROST_DAN === 'da'){
          _this.events.push({
            title: 'PRAZNIK: ' + val.IME_PRAZNIKA,
            start: _this.moment(val.DATUM, 'DD.MM.YYYY').startOf('day').toDate(),
            end: _this.moment(val.DATUM, 'DD.MM.YYYY').endOf('day').toDate(),
            id: 'praznik_' + idx,
            stick: true,
            allDayEvent: true,
          });
        }
      });

      console.log("this.events", _this.events);

    },
    async getReservations() {
      const _this = this;

      _this.$vs.loading();

      _this.events = [];

      let month = _this.moment(_this.defaultDate).format("M");
      let year = _this.moment(_this.defaultDate).format("YYYY");
      // console.log("month", month);
      // console.log("year", year);
      //
      // console.log("_this.$globalFunctions.getCurrentUnit", _this.$globalFunctions.getCurrentUnit().id);


      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'dashboard/' + _this.$globalFunctions.getCurrentUnit().id + '/' + year + '/' + month)
        .then((res) => {


          // console.log(res.data);
          let events = [];
          _.each(res.data.data, function (item) {

            let couponHtml = '';
            if (item.coupons.length > 0 && _.filter(item.coupons, {deleted: 0}).length > 0) {
              couponHtml = '<div>KUPON</div>';
              console.log("item.coupons", item.coupons);
            }

            let color = item.car ? item.car.color : '#a1a1a1';
            let intermodalTransportation = '';
            let icon = null;

            if(item.intermodalReservations && item.intermodalReservations[0]) {

              let instructions = JSON.parse(item.intermodalReservations[0].instructions)[0];
              console.log("instructions", instructions);

              if(instructions.transit_details.line.vehicle.name.toLowerCase() === 'vlak') {
                icon = require('@/assets/images/content/travelPlan/vlak1.svg');
              } else {
                icon = require('@/assets/images/content/travelPlan/bus.svg');
              }

              intermodalTransportation = '<div class="intermodalTransportation"><img src="' + icon + '" class="transportationTypeIcon"/></div>';
            }

            let showCompanionIcon = item.companionId != null ? 'shown' : 'hidden';
            console.log("showCompanionIcon", showCompanionIcon);

            let eventItem = {
              title: '<div style="position: relative"><img src=\"' + _this.getVolunteerProfileImage(item) + '" class="rounded-full shadow-md cursor-pointer block volunteersImageInCalendar\"/><img src=\"' + _this.companion + '" class="' + showCompanionIcon + ' rounded-full shadow-md cursor-pointer block companionImageInCalendar\"/><div class=\"volunteer\">' + _this.getVolunteerName(item) + '</div><div class=\"timeRange\">' + _this.moment(item.departureTime).format('HH:mm') + '-' + _this.moment(item.arrivalTime).format('HH:mm') + '</div><div class=\"clearfix\"></div><span class=\"customer\">' + item.customer.firstName + ' ' + item.customer.lastName + '</span><div class=\"addresses\"><i class=\"fa fa-home\"></i> ' + (item.departure ? item.departure.quickSearch.replace(', Slovenija', '') : '/') + '<br/> <i class=\"fa fa-arrow-circle-right\"></i> ' + (item.destination ? item.destination.quickSearch.replace(', Slovenija', '') : '/') + '<br/> <i class=\"fa fa-flag\"></i> ' + (item.arrival ? item.arrival.quickSearch.replace(', Slovenija', '') : '/') + (couponHtml ? '<div class=\"coupon\">' + couponHtml + '</div>' : '') + '<button type="button" name="button" class="editButton vs-component vs-button vs-button-dark vs-button-border small" style="background: transparent;" onclick="window.location.href=\'' + window.location.origin + '/rezervacije/rezervacija/' + item.id + '\'; event.stopPropagation();"><span class="vs-button-text vs-button--text"><i class="vs-icon notranslate icon-scale vs-button--icon material-icons null" style="order: 0; margin-right: 0px; margin-left: 0px;">edit</i></span>' + intermodalTransportation + '</div></div>',
              start: new Date(item.departureTime),
              end: new Date(item.arrivalTime),
              backgroundColor: item.waitingForReview ? '#ffc107' : color,
              borderColor: item.waitingForReview ? '#ffc107' : color,
              id: item.id,
              stick: true,
              textEscape: false,
              className: 'reservationEvent',
              html: true
            };
            events.push(eventItem);
          });

          _this.events = events;
          _this.renderHolidays();
          setTimeout(() => {
            _this.$vs.loading.close();
          }, 100);


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    async getCoupons() {
      const _this = this;

      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'coupons', {
        params: {
          unit: _this.$globalFunctions.getCurrentUnit().id,
          startDate: _this.moment(_this.defaultDate).startOf('month').valueOf(),
          endDate: _this.moment(_this.defaultDate).endOf('month').valueOf(),
        }
      })
        .then((res) => {
          console.log("res.data.data", res.data.data);
          //_this.couponsData = _.filter(res.data.data, {unit_id: _this.$globalFunctions.getCurrentUnit().id});
          _this.couponsData = res.data.data;
          console.log("_this.couponsData", _this.couponsData);
          _this.$vs.loading.close();

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    getVolunteerProfileImage(item) {
      const _this = this;

      if (item.volunteer) {
        return _this.$globalFunctions.getUserAvatar(item.volunteer.profileImage)
      } else {
        return 'https://via.placeholder.com/500?text=?'
      }

    },

    getVolunteerName(item) {
      const _this = this;

      if (item.volunteer) {
        return item.volunteer.firstName + ' ' + item.volunteer.lastName
      } else {
        return 'ni znano'
      }

    },

    handleCalendarDateClick(info) {
      console.log("handleCalendarDateClick", info);
    },
    handleEventClick(info) {
      console.log("handleEventClick", info);
      const _this = this;

      console.log("clicked reservation: " + info.event.id);

      if(info.event.id.indexOf('praznik') > -1) {
        return
      }

      _this.reservationId = parseInt(info.event.id);
      _this.sidebarActive = true;
    },
    handleCalendarDatesRender(info) {
      const _this = this;
      console.log("handleCalendarDatesRender", info);

      let calendarApi = _this.$refs.fullCalendar.getApi();

      if (_this.defaultDate != _this.moment(calendarApi.getDate()).format('YYYY-MM-DD')) {
        _this.defaultDate = _this.moment(calendarApi.getDate()).format('YYYY-MM-DD');
        this.$store.dispatch('setSelectedDateForReservations', _this.moment(_this.defaultDate,'YYYY-MM-DD').toDate());

        _this.getReservations();
      }
    },
    handleCalendarDayRender(info) {
      // console.log("handleCalendarDayRender", info);
    },
    handleEventRender(info) {
      if (info.event.title) {
        info.el.firstChild.innerHTML = info.event.title;
      }
    },
    closeSideBar(reloadReservations) {
      const _this = this;

      _this.reservationId = null;
      _this.sidebarActive = false;

      if (reloadReservations) {
        _this.getReservations();
      }
    },
    prevMonth() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.prev();
    },
    nextMonth() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.next();
    },
    onDateChange() {
      const calendarApi = this.$refs.fullCalendar.getApi();
      calendarApi.gotoDate(this.defaultDate); // Pass a Date object or date string
    },
    customDateFormatter(date) {
      return this.moment(date).format('MMMM, YYYY');
    },

  }
}
</script>
<style lang="scss">


</style>
